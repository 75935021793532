$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

#sn-site-logo {
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -620px;
  width: 215px;
  /* height: $site-logo-size; */
  height:135px;
  padding: 8px;
  background-color: transparent;
  border: none;
  z-index: 1000;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;

  .nav-fixed & {
    width: $site-logo-size-fixed;
    height: $site-logo-size-fixed;
    top:-20px;
  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
  }
}
