/* Player Container */
.playerContainer {
	background: url($asset-path+"/playerContainerBG.jpg") center center no-repeat #000;
	margin-bottom: 30px;
	position: relative;
	h3 {
		font-size: 30px;
		color: #fff;
	}
	&:after {
		content: ' ';
		background: url($asset-path+"/snStacked.svg") no-repeat center center;
		position: absolute;
		width: 55px;
		height: 55px;
		bottom: 10px;
		right: 10px;
	}
	.dataTable tbody td {
		background: rgba(82,207,77,0.6);
		color: #fff;
	}
	.contactElement h3 {
		font-size: 24px;
		color: #fff;
	}
	.heroPhotoElement img {
		border: none;
		width: auto;
		max-height: 340px;
	}
	.pageElement.heroPhotoElement {
		margin-top: 15px;
	}
	.pageElement.textBlockElement.clearfix {
    margin-top: 60px;
    margin-bottom: 0px;
	}
	.heroPhotoElement .cutline {
		border-bottom: none;
		border-left: none;
		border-right: none;
	}
	.cutline {
		text-align: center;
		background-color: transparent;
	}
}
@media screen and (max-width: 1024px) {
	.playerContainer {
		margin-top: 15px;
		.pageElement.heroPhotoElement {
			margin-top: 35px;
			width: 90%;
			img {
				width: 100%;
			}
		}
	}
	
}
@media screen and (max-width: 768px) {
	.playerContainer {
		.pageElement.heroPhotoElement {
			width: 100%;
			img {
				width: 80%;
			}
		}
		.pageElement.textBlockElement.clearfix {
			margin-top: 0;
		}
	}
}